import React from "react"
import {
  Layout,
  Section,
  TextContainer,
  Banner,
  Hero,
  Card,
  CustomButton,
} from "../components"

import * as styles from "../styles/products.module.scss"

export default function Products() {
  return (
    <Layout theme="dark">
      <Banner text="Get 70% VCF grant for charities. Contact us to find out more!"></Banner>
      <Hero
        className={styles.productsHero}
        image={{ src: "products.svg", alt: "products-logo" }}
        hero={{
          title:
            "S B Tan Audit PAC believes in technological tools to improve our workflow",
          text: "Contact us to find out more.",
        }}
      ></Hero>
      <Section className={styles.productsSection}>
        <h1>
          We offer various accounting solutions, depending on your business
          needs.
        </h1>
        <TextContainer
          className={styles.productsTextContainer}
          title="Sage 50"
          text="Previously known as Peachtree, Sage 50 is an established commercial accounting software for almost 30 years. It is user-friendly for non-accounting personnel, simply enter your data, save, and view the reports. Sage 50 is suitable for Singapore reporting requirements, including GST returns, and comes with many customisable templates (invoices, quotes and statements) to suit your needs."
        ></TextContainer>
        <span className={styles.productsContact}>
          Contact us at admin@sbtan.com
        </span>
        <CustomButton
          target="_blank"
          to="https://docs.google.com/forms/d/e/1FAIpQLSfKQqsJYwZOivOBs5nXU8Stqx1MP3kc6dldl1ldRvAvUwxubg/viewform"
        >
          Order Now!
        </CustomButton>
        <div className={styles.productsCardContainer}>
          <Card
            img={{ src: "product-1.jpg", alt: "sage50-pro" }}
            title="Sage 50 Premium Account"
            text="from S$690"
          ></Card>
          <Card
            img={{ src: "product-2.jpg", alt: "sage50-premium" }}
            title="Sage 50 Premium Account"
            text="from S$930"
          ></Card>
          <Card
            img={{ src: "product-3.jpg", alt: "sage50-quantum" }}
            title="Sage 50 Premium Account"
            text="from S$990/user"
          ></Card>
        </div>
        <TextContainer
          title="Xero"
          text="Xero is a feature-rich accounting software that is cloud-based. Suitable for small businesses to established businesses of all sizes, Xero is integrable with many 3rd party applications that can make your transition seemless."
        ></TextContainer>
      </Section>
    </Layout>
  )
}
